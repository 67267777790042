import React, { ReactElement, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ScreenRectangle from '../components/screen-rentangle/screen-rectangle'
import { Col, Container, Row } from 'react-grid-system'
import Surface from '../components/surface/surface'
import { BrowserView, isAndroid, isIOS, isMobile, MobileView } from 'react-device-detect'
import QRCode from 'react-qr-code'
import { useIntl } from 'react-intl'
import useWindowDimensions from '../utils/use-window-dimension'

const ProxyScreen = (): ReactElement => {
  const orgSettings = {
    color: '#2374C5',
    logo: 'https://images.jigx.com/jigx-email-logo.png',
    namespace: 'jigxgsd',
    appName: 'Jigx//GSD',
    deeplink: 'jigxgsd.jigx.com',
    appIdIOS: 'com.jigx.jigxgsd.ios',
    appIdAndroid: 'com.jigx.jigxgsd.android',
    appStoreURL: '#',
    playStoreURL: '#',
  }

  const { height } = useWindowDimensions()
  const phoneHeight = height * 0.9
  const { formatMessage } = useIntl()
  const appDownloadLink = isIOS ? orgSettings.appStoreURL : orgSettings.playStoreURL
  const pathname = document.location.pathname
  const params = document.location.search
  const appLink = `${orgSettings.deeplink}:/${pathname}${params}`
  const androidIntend = `intent://scan/#Intent;scheme=${appLink};package=${orgSettings.appIdAndroid};end`
  const QRCodeLink = `https://${orgSettings.deeplink}${pathname}${params}`
  const redirectLink = isIOS ? appLink : androidIntend

  useEffect(() => {
    window.location.href = redirectLink
  }, [appDownloadLink, redirectLink])

  return (
    <>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/favicon-16x16.png`}
        />
        <link
          rel="manifest"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/site.webmanifest`}
        />
        <link
          rel="mask-icon"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/safari-pinned-tab.svg" color="#ee212c`}
        />
        <link
          rel="shortcut icon"
          href={`https://images.jigx.com/favicons/${orgSettings.namespace}/favicon.ico`}
        />
        <meta
          name="apple-itunes-app"
          content={`app-id=${orgSettings.appIdIOS}, app-argument=${appLink}`}
        />
        <meta name="msapplication-TileColor" content={orgSettings.color} />
        <meta
          name="msapplication-config"
          content={`https://images.jigx.com/favicons/${orgSettings.namespace}/browserconfig.xml`}
        />
        <meta name="theme-color" content={orgSettings.color} />
      </Helmet>
      <ScreenRectangle color={orgSettings.color} />
      <div className="screen-wrapper">
        <div className="content-container">
          <Container style={{ maxWidth: '530px' }}>
            <Row>
              <Col xs={12} className="flex--align-center flex">
                <Surface isMobile={isMobile}>
                  <div className="logo__container">
                    <img
                      className="logo__img"
                      alt={`${orgSettings.appName} logo`}
                      height={phoneHeight}
                      style={{ maxWidth: '375' }}
                      src={orgSettings.logo}
                    />
                  </div>
                  <h1>
                    {formatMessage(
                      {
                        id: 'title-redirect-to-app',
                        defaultMessage: 'Redirect to {appName}',
                      },
                      {
                        appName: orgSettings.appName,
                      },
                    )}
                  </h1>
                  {!isMobile && (
                    <BrowserView>
                      <Row>
                        <Col xs={12}>
                          <div className="flex--justify-center flex">
                            <div
                              style={{
                                padding: '5px',
                                paddingBottom: 0,
                                backgroundColor: 'white',
                              }}>
                              <QRCode value={QRCodeLink} size={100} />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </BrowserView>
                  )}
                  <MobileView>
                    <Row>
                      <Col xs={12}>
                        <div className="padding-bottom--medium">
                          <a
                            href={redirectLink}
                            className="button button-link"
                            style={{ backgroundColor: orgSettings.color }}>
                            {formatMessage({
                              id: 'button-open-link',
                              defaultMessage: 'Open link',
                            })}
                          </a>
                        </div>
                        <div className="text--center">
                          {formatMessage({
                            id: 'button-open-link',
                            defaultMessage: 'or',
                          })}
                        </div>
                        <div className="padding-top--medium">
                          <div className="padding-bottom--large store-logo__container flex">
                            {isIOS && orgSettings.appStoreURL && (
                              <a href={orgSettings.appStoreURL}>
                                <img
                                  src="https://images.jigx.com/email-app-store.png"
                                  height="60"
                                  alt="AppStore"
                                  className="store-logo"
                                />
                              </a>
                            )}
                            {isAndroid && orgSettings.playStoreURL && (
                              <a href={orgSettings.playStoreURL}>
                                <img
                                  src="https://images.jigx.com/email-google-play.png"
                                  height="60"
                                  alt="PlayStore"
                                  className="store-logo"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </MobileView>
                </Surface>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="flex--justify-center padding-top--medium flex">
                <p>Powered by Jigx, Inc.</p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default React.memo(ProxyScreen)
